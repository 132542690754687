import React, { useRef } from "react"
import cn from "classnames"

function PricePlansItem({ plan, activePlan, setActivePlan }) {
  const planRef = useRef(null)

  const scrollToPlan = () => {
    setTimeout(() => {
      window.scrollTo({
        top: planRef.current.offsetTop - 60,
        behavior: "smooth",
      })
    }, 1000)
  }

  const openCard = () => {
    scrollToPlan()
    setActivePlan(plan.label)
  }

  const closeCard = () => {
    scrollToPlan()
    setActivePlan(null)
  }

  return (
    <div key={plan.id} ref={planRef} className={cn("PricePlans-box", {
      active: plan.label === activePlan,
    })}>
      <div className="PricePlans-header">
        <div className="PricePlans-img">
          {plan.image}
        </div>
        <div className="PricePlans-plan">
          {plan.type}
        </div>
        <div className="PricePlans-price">
          <b>{plan.price}$ ㎡</b>
        </div>
        <div className="PricePlans-square">
          от {plan.square} ㎡
        </div>

        <div
          className="PricePlans-plan-expand"
          onClick={openCard}
        >
          Подробнее
        </div>
      </div>
      <div className="PricePlans-body">
        <ul className="PricePlans-list">
          {
            plan.services.map(service => (
              <li className="PricePlans-item">
                {service}
              </li>
            ))
          }
        </ul>
        <a href="#contact-us" className="btn primary">
          Заказать
        </a>

        <div
          className="PricePlans-plan-expand"
          onClick={closeCard}
        >
          Свернуть
        </div>
      </div>
    </div>
  )
}

export default PricePlansItem

import React from "react"
import Slider from "react-slick"
import { CustomCarouselArrow } from "../../CustomCarouselArrow"

import step1 from "../../../images/steps-02.png"
import step2 from "../../../images/steps-03.png"
import step3 from "../../../images/steps-04.png"
import step4 from "../../../images/steps-05.png"
import step5 from "../../../images/steps-06.png"

import "./Steps.scss"

const steps = [
  {
    id: 1,
    image: step1,
    text: "Самое первое и самое главное, с чего стоит начать- это организация пространства. Перепланировка\n" +
      "                немного пугает, но без нее не обойтись, это решающее звено. В процессе перепланировки\n" +
      "                принимаются решения по поводу стен, перегородок, размещения дверных проемов и т.д.",
  },
  {
    id: 2,
    image: step2,
    text: "Далее необходимо выбрать стилистическое направление. Этот выбор возможен только после\n" +
      "обсуждения всех деталей и предпочтений внутреннего декора.",
  },
  {
    id: 3,
    image: step3,
    text: "По итогам предыдущих пунктов прорабатываются несколько вариантов планировочных решений,\n" +
      "среди которых Клиент уже может выбрать подходящий или же совместить несколько элементов в\n" +
      "единый и окончательный вариант планировки. Также на этом этапе прорабатывается решение\n" +
      "будущего расположения мебели, техники, коммуникаций.",
  },
  {
    id: 4,
    image: step4,
    text: "Одна из самых приятных стадий работы над дизайн- проектом – это визуализация. Когда Ваше\n" +
      "    будущее помещение выстраивается в реальных размерах, моделируется мебель, отделка, текстиль,\n" +
      "    детали. Именно на визуализации Вы получаете возможность увидеть то, чего еще не существует.\n" +
      "    Внести коррективы или наоборот, убедится в правильности своих желаний.",
  },
  {
    id: 5,
    image: step5,
    text: "Последней и важной частью на пути к Вашему будущему дому является его техническая часть. Тут\n" +
      "учитывается все, от полета фантазии и пожеланий до функциональных реальностей. В итоге Вы\n" +
      "получаете набор технической документации со всей необходимой информацией для строителей.\n" +
      "Именно грамотно проработанные чертежи сделают возможным процесс стройки и ремонта.",
  },
]

function Steps() {

  const settings = {
    infinite: false,
    dots: true,
    center: true,
    speed: 100,
    slidesToShow: 3,
    slidesToScroll: 1,
    lazyLoad: true,
    arrows: false,
    focusOnSelect: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  }

  return (
    <section id="steps" className="Steps">
      <div className="container">
        <h2 className="section-title">
          Этапы создания дизайн-проекта
        </h2>
        <Slider {...settings}>
          {
            steps.map(item => (
              <div key={item.id}>
                <div className="Steps-box">
                  <img className="Steps-image" src={item.image} alt=""/>
                  <p className="Steps-text">
                    {item.text}
                  </p>
                </div>
              </div>
            ))
          }
        </Slider>
      </div>
    </section>

  )
}

export default Steps

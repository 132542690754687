import React, { useState, useEffect } from "react"
import Slider from "react-slick"
import { Link } from "gatsby"
import axios from "axios"
import "./Portfolio.scss"

function Portfolio() {
  const [projects, setProjects] = useState([])

  const settings = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    lazyLoad: true,
    arrows: false,
    focusOnSelect: false,
    responsive: [
      {
        breakpoint: 1030,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  }


  useEffect(() => {
    (async () => {
      const url = process.env.REACT_URL
      await axios({
        method: "GET",
        url: `${url}/gallery/buildings`,
      }).then(res => {
        if (!res) return
        setProjects(res.data)
      })
    })()

  }, [])

  return (
    <section id="works" className="Portfolio">
      <div className="container Portfolio-header">
        <h2 className="Portfolio-title section-title">
          Наши работы
        </h2>

        <Link
          to="/projects/"
          className="btn"
        >
          Все проекты
        </Link>
      </div>
      <Slider {...settings}>
        {
          projects.map(item => (
            <div className="Portfolio-slider" key={item.id}>
              <div className="Portfolio-box" style={{ backgroundImage: `url('${item.poster}')` }}>
                <div className="Portfolio-wrap">
                  <p className="Portfolio-location">
                    {`${item.location && `${item.location}, `}${item.square} кв. м.`}
                  </p>
                  <h3 className="Portfolio-building">
                    {item.name}
                  </h3>
                </div>
              </div>
              <Link to={`project?id=${item.id}`} className="btn white">
                Посмотреть проект
              </Link>
            </div>
          ))
        }
      </Slider>
    </section>

  )
}

export default Portfolio

import React from "react"
import about from "../../../images/about-us.jpg"
import "./AboutUs.scss"

function AboutUs() {

  return (
    <section id="about" className="AboutUs">
      <div className="container">
        <div className="AboutUs-content">
          <div className="AboutUs-img">
            <img src={about} alt=""/>
          </div>

          <div className="AboutUs-text">
            <h2 className="AboutUs-title section-title">
              О нас
            </h2>
            <div className="AboutUs-text-w">
              <p>
                Наша студия дизайна и визуализации интерьеров Hard Berry Design специализируется в основном на
                современных стилях. При этом мы имеем успешный опыт ведения от обмеров до реализации и
                классических помещений. Над каждым проектом мы работаем индивидуально в соответствии с
                Вашими вкусами и Вашими задачами. Мы учитываем все новейшие веяния и тенденции
                современных решений с учетом истории.
              </p>
              <p>
                При разработке каждого дизайн- проекта, подборе материалов, фактур отделки, элементов мебели,
                мы основываемся на предпочтениях наших Клиентов. Мы считаем, что поиск оптимального и
                продуманного решения сделает Ваш интерьер по- настоящему красивым и комфортным, а также
                позволит избежать необоснованных затрат на каждом этапе реализации проекта. Благодаря опыту
                мы готовы к решению самых неординарных задач, сложных по стилю, конструкциям, пожеланиям.
              </p>
              <p>
                Наши специалисты полностью преображают пространство, вносят в него нотку рациональности,
                необычности, свою изюминку. В работе мы используем современные технологии проектирования,
                благодаря чему есть возможность продумать расположение каждой детали, проанализировать все
                возможные варианты решений и все нюансы при реализации проекта.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutUs

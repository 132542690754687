import React from "react"

function Express() {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 81.16 41.61" width="100" height="60">
      <path
        d="M28.52,38.3a1.54,1.54,0,0,0-.17-.13,37,37,0,0,1-14.24-12A43.84,43.84,0,0,1,8.29,15.19a.65.65,0,0,0-.37-.39c-1.64-.37-3.29-.71-5.09-1.08.71,1.92,1.33,3.82,2.1,5.65A34.54,34.54,0,0,0,23.33,38.2a1.63,1.63,0,0,0,.65.15c1.39,0,2.78,0,4.16,0Z"/>
      <path
        d="M15.24,15.87a.65.65,0,0,0-.42-.3c-1.07-.11-2.15-.17-3.23-.27-.57-.05-1.14-.13-1.78-.21.07.22.12.38.18.53a43.33,43.33,0,0,0,6.36,11,34.61,34.61,0,0,0,14.4,11,3.84,3.84,0,0,0,2.38.27c.79-.18,1.59-.29,2.38-.43a1.82,1.82,0,0,0-.56-.4C25.87,32.33,19.1,25.46,15.24,15.87Z"/>
      <path
        d="M38.12,36.76c.72-.21,1.44-.46,2.24-.73l-.48-.31c-2-1.37-4-2.64-5.91-4.12A34.24,34.24,0,0,1,24.24,20c-.71-1.43-1.26-2.93-1.9-4.42l-5.58.17a1.55,1.55,0,0,0,.06.23c.42.91.81,1.84,1.28,2.72,4.36,8.35,11,14.18,19.54,18.06A.67.67,0,0,0,38.12,36.76Z"/>
      <path
        d="M42.21,35.18c.67-.34,1.33-.7,2-1.09-8.13-4.21-12.5-11.19-15-19.56L24,15.4s0,0,0,.08.07.23.11.35A26.56,26.56,0,0,0,27.2,22.2c3.74,5.6,8.85,9.66,14.64,12.94A.45.45,0,0,0,42.21,35.18Z"/>
      <path
        d="M30.74,14.18l0,.19a31.82,31.82,0,0,0,6.89,12.89,23.63,23.63,0,0,0,7.65,5.68c.14.06.39.11.48,0,.77-.63,1.5-1.28,2.31-2-6.93-4.26-11.68-10-12.91-18.12Z"/>
      <path
        d="M53.88,29.88c.11-.14.2-.28.31-.43l-3.4-.78-.11.15a17.12,17.12,0,0,1-4.7,5,27.2,27.2,0,0,1-10.85,4.48,46.48,46.48,0,0,1-9.12.84l0,.14,4.18,1.24a.3.3,0,0,0,.13,0c.67-.07,1.33-.16,2-.22a37.45,37.45,0,0,0,10.89-2.57A25.81,25.81,0,0,0,53.88,29.88Z"/>
      <path
        d="M32.26,12.9c1.47-.43,2.91-1,4.36-1.49A.73.73,0,0,0,37,10.18a.81.81,0,0,0-.93-.13c-1.71.58-3.41,1.22-5.16,1.68a50.31,50.31,0,0,1-16.62,1.5,45.48,45.48,0,0,1-13.2-2.64.74.74,0,0,0-1,.39.71.71,0,0,0,.47,1c.45.19.9.36,1.37.51a53,53,0,0,0,15.28,2.38A53,53,0,0,0,32.26,12.9Z"/>
      <path
        d="M59,30.73l.35-.47c-1.18-.17-2.29-.35-3.4-.49-.11,0-.26.12-.35.22-.55.67-1,1.38-1.63,2A28.42,28.42,0,0,1,40.9,40.16c-1.62.51-3.29.87-4.93,1.29v.06l5.2.1a.84.84,0,0,0,.27,0c1.5-.5,3-.93,4.49-1.51A31.41,31.41,0,0,0,59,30.73Z"/>
      <path
        d="M81.16,28.43c-.07-.47-.46-.67-1.15-.58h0c-2.34.2-4.67.46-7,.58a76.62,76.62,0,0,1-14.44-.63A44.78,44.78,0,0,1,50.1,26c-.52-.17-.89,0-1,.41s0,.8.55,1,1.36.44,2,.62a74.16,74.16,0,0,0,17.21,2,93.07,93.07,0,0,0,11.32-.7C80.81,29.25,81.23,29,81.16,28.43Z"/>
      <path
        d="M63.91,31.05c.08-.1.14-.22.24-.36l-2.82-.24s-.06,0-.09,0a1.23,1.23,0,0,0-.35.22,31.86,31.86,0,0,1-11.58,9.5c-.65.32-1.31.62-2,.93l0,.06c.23,0,.46,0,.68-.08a23.06,23.06,0,0,0,4.1-.8A25.79,25.79,0,0,0,63.91,31.05Z"/>
      <path
        d="M5.68,10.67a27.94,27.94,0,0,0,6.74-5.41c1.19-1.35,2.3-2.79,3.52-4.28a6.48,6.48,0,0,0,.51.59,39,39,0,0,0,13.8,8.86,2.39,2.39,0,0,0,2.21,0,0,0,0,0,0,0,0c-.74-.26-1.49-.51-2.23-.78A39,39,0,0,1,16.27.38c-.54-.53-.63-.52-1.06.12a28.93,28.93,0,0,1-11,10.11.92.92,0,0,0-.14.11A1.59,1.59,0,0,0,5.68,10.67Z"/>
      <path
        d="M69.5,30.84c-1,0-2.1,0-3.15-.07a.68.68,0,0,0-.69.36,28.05,28.05,0,0,1-5.6,6.16c-.68.55-1.39,1.07-2.18,1.67.6-.17,1.08-.28,1.55-.44a17,17,0,0,0,3.51-1.27,26.37,26.37,0,0,0,6.93-6.37A2.94,2.94,0,0,0,69.5,30.84Z"/>
      <path
        d="M56.42,26.27a24.69,24.69,0,0,0,8.63-5.51c.39-.38.81-.73,1.23-1.11,0,.08.15.21.25.35a34.76,34.76,0,0,0,7.08,7.2,1.89,1.89,0,0,0,1.64.27c-.06-.06-.08-.1-.11-.13a31.6,31.6,0,0,1-8.48-8.46c-.3-.43-.5-.42-.86,0a23.19,23.19,0,0,1-10.48,7l-1.2.4A3.85,3.85,0,0,0,56.42,26.27Z"/>
      <path
        d="M73.31,30.73a2.42,2.42,0,0,0-2.45,1.2c-.87,1.21-2,2.26-2.94,3.37a29.8,29.8,0,0,0,7.76-4.71l-1.24.08A7.2,7.2,0,0,1,73.31,30.73Z"/>
    </svg>
  )
}

export default Express

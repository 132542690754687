import React, { useState } from "react"
import cn from "classnames"
import Express from "./icons/express"
import Optimal from "./icons/optimal"
import Premium from "./icons/premium"
import Author from "./icons/author"

import "./PricePlans.scss"
import PricePlansItem from "./PricePlansItem"

const PLAN_LABEL = {
  EXPRESS: "express",
  OPTIMAL: "optimal",
  PREMIUM: "premium",
  AUTHOR: "author",
}

const plans = [
  {
    id: 1,
    type: "Экспресс",
    label: PLAN_LABEL.EXPRESS,
    price: "15",
    square: "55",
    image: <Express/>,
    services: [
      "Обмерный чертеж ",
      "План демонтажа перегородок и других конструкций при необходимости",
      "План монтажа перегородок ",
      " План расположения мебели. Указание размеров помещений и предметов интерьера",
      " Экспликация помещений. Маркировка по плану с названием и указанием площади",
      "План потолков. Размеры, конфигурация и элементы освещения",
      " Схемы разрезов. При необходимости выполняется 2-3 разреза",
      "Узлы конструкций. Набор стандартных узлов решений для используемых конструкций",
      "Вид.  Конструкция декоративных элементов.",
      "Схема сценария освещения",
      "Эскизный чертеж плана электрики",
      " План привязки слабых токов",
      " План привязки выключателей",
      "План пола с указанием типов покрытий",
      "План с указанием теплых полов",
      " План расположения сантехники",
      " План расположения кондиционеров",
      "Схема устройства охранной сигнализации/ Рекомендательный",
      " План маркировки дверных проемов",
    ],
  },
  {
    id: 2,
    type: "Оптимальный",
    label: PLAN_LABEL.OPTIMAL,
    price: "25",
    square: "55",
    image: <Optimal/>,
    services: [
      "Визуализация помещений, с реально подобранными предметами мебели, освещения, покрытий",
      "Обмерный чертеж",
      "План демонтажа перегородок",
      "План монтажа перегородок и других конструктивных элементов при необходимости",
      "План расположения мебели. Указание размеров помещений и предметов интерьера",
      "Экспликация помещений. Маркировка по плану с названием и указанием площади",
      "План потолков. Размеры, конфигурация и элементы освещения ",
      "Схемы разрезов. При необходимости выполняется 2-3 разреза",
      "Узлы конструкций. Набор стандартных узлов решений для используемых конструкций",
      "Вид.  Конструкция декоративных элементов. ",
      "Схема сценария освещения",
      "Эскизный чертеж плана электрики",
      "План привязки слабых токов ",
      "План привязки выключателей",
      "План пола с указанием типов покрытий ",
      "План с указанием теплых полов",
      "План расположения сантехники ",
      "План расположения кондиционеров ",
      "Схема устройства охранной сигнализации/ Рекомендательный",
      "План маркировки дверных проемов",
      "Развертка по кухне и другой мебели, в случае проектирования индивидуального изделия",
      "Раскладки плитки напольной по всем помещениям, где используется плитка. С указанием количества",
      "Раскладки плитки по стенам на развертках. По всем помещениям, где используется плитка. С указанием количества.\n",
    ],
  },
  {
    id: 3,
    type: "Премиум",
    label: PLAN_LABEL.PREMIUM,
    price: "35",
    square: "55",
    image: <Premium/>,
    services: [
      "Визуализация помещений, с реально подобранными предметами мебели, освещения, покрытий",
      "Обмерный чертеж ",
      "План демонтажа перегородок",
      "План монтажа перегородок и других конструктивных элементов при необходимости",
      "План расположения мебели. Указание размеров помещений и предметов интерьера",
      "Экспликация помещений. Маркировка по плану с названием и указанием площади ",
      "План потолков. Размеры, конфигурация и элементы освещения ",
      "Схемы разрезов. При необходимости выполняется 2-3 разреза",
      "Узлы конструкций. Набор стандартных узлов решений для используемых конструкций",
      "Вид.  Конструкция декоративных элементов. ",
      "Схема сценария освещения",
      "Эскизный чертеж плана электрики",
      "План привязки слабых токов ",
      "План привязки выключателей",
      "План пола с указанием типов покрытий ",
      "План с указанием теплых полов",
      "План расположения сантехники ",
      "План расположения кондиционеров ",
      "Схема устройства охранной сигнализации/ Рекомендательный",
      "План маркировки дверных проемов",
      "Развертка по кухне и другой мебели, в случае проектирования индивидуального изделия",
      "Раскладки плитки напольной по всем помещениям, где используется плитка. С указанием количества",
      "Раскладки плитки по стенам на развертках. По всем помещениям, где используется плитка. С указанием количества.",
      "Бесплатная консультативная поддержка на протяжении всего периода реализации",
      "Авторский надзор",
    ],
  },
  {
    id: 4,
    type: "Авторский",
    label: PLAN_LABEL.AUTHOR,
    price: "45",
    square: "100",
    image: <Author/>,
    services: [
      "Визуализация помещений, с реально подобранными предметами мебели, освещения, покрытий",
      "Обмерный чертеж ",
      "План демонтажа перегородок",
      "План монтажа перегородок и других конструктивных элементов при необходимости",
      "План расположения мебели. Указание размеров помещений и предметов интерьера",
      "Экспликация помещений. Маркировка по плану с названием и указанием площади ",
      "План потолков. Размеры, конфигурация и элементы освещения ",
      "Схемы разрезов. При необходимости выполняется 2-3 разреза",
      "Узлы конструкций. Набор стандартных узлов решений для используемых конструкций",
      "Вид.  Конструкция декоративных элементов. ",
      "Схема сценария освещения",
      "Эскизный чертеж плана электрики",
      "План привязки слабых токов ",
      "План привязки выключателей",
      "План пола с указанием типов покрытий ",
      "План с указанием теплых полов",
      "План расположения сантехники ",
      "План расположения кондиционеров ",
      "Схема устройства охранной сигнализации/ Рекомендательный",
      "План маркировки дверных проемов",
      "Развертка по кухне и другой мебели, в случае проектирования индивидуального изделия",
      "Раскладки плитки напольной по всем помещениям, где используется плитка. С указанием количества",
      "Раскладки плитки по стенам на развертках. По всем помещениям, где используется плитка. С указанием количества.",
      "Бесплатная консультативная поддержка на протяжении всего периода реализации",
      "Авторский надзор",
      "Услуга комплектации. Полное сопровождение реализации.",
    ],
  },
]

function PricePlans() {
  const [isExtendInfo, setExtendInfo] = useState(false)
  const [activePlan, setActivePlan] = useState(null)

  const toggleExtendInfo = () => setExtendInfo(prevState => !prevState)

  return (
    <section id="price" className="PricePlans">
      <div className="container">
        <h2 className="section-title">Стоимость</h2>
        <div className="PricePlans-plans">
          {
            plans.map(plan => (
              <PricePlansItem
                plan={plan}
                activePlan={activePlan}
                setActivePlan={setActivePlan}
              />
            ))
          }
        </div>
        <div className="PricePlans-extra-info">
          <div className={cn("PricePlans-extra-text", {
            active: isExtendInfo,
          })}>
            <p>
              Окончательна стоимость разработки дизайн- проекта зависит от многих параметров и может быто точно
              определена исключительно в индивидуальном порядке. Большое влияние на формирование стоимости услуг имеет
              площади помещения и выбранный Вами стиль. Так, сложные стилевые решения приводят к удорожанию, и, в то же
              время, при площади помещения свыше 100 кв.м. предоставляется скидка.
            </p>
            <p>
              Услуга авторского надзора также предоставляется согласно Вашей индивидуальной ситуации. Это может быть,
              как комплексная ставка «все включено», так и единоразовые выезды на объект с почасовой оплатой. Пакет
              «Премиум» и Авторский» изначально включает в себя полный авторский надзор со значительной скидкой.
            </p>
            <p>
              Стоимость дизайна интерьера других помещений, таких как рестораны, бары, офисы, спа, фитнесс-центры и др.,
              может быть определена в индивидуальном порядке. Обращайтесь, мы найдем взаимовыгодные условия
              сотрудничества.
            </p>
            <p>
              Услуги индивидуального дизайнера интерьера стоят совсем недорого, поскольку это Ваш дом! И в дальнейшем он
              будет Вас приятно радовать и задавать тон Вашей жизни!
            </p>
          </div>
          <div className="PricePlans-extra-btn" onClick={toggleExtendInfo}>
            {isExtendInfo ? "Свернуть" : "Читать далее"}
          </div>
        </div>
      </div>
    </section>
  )
}

export default PricePlans

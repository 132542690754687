import React from "react"

function Optimal() {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 39.1 69.78" width="40" height="70">
      <path
        d="M32.54,25.68c-.44-7.33-.89-14.66-1.31-22A3.61,3.61,0,0,0,27.47,0c-1.57,0-3.13,0-4.7,0-3.71,0-7.42,0-11.13,0A3.56,3.56,0,0,0,7.9,3.31c-.23,3.52-.42,7-.63,10.58L6.43,28c-.13,2.08-.24,4.17-.36,6.33H33C32.86,31.42,32.71,28.55,32.54,25.68Z"/>
      <path
        d="M2.74,39.7A3.34,3.34,0,0,0,6,42.47c4.49,0,9,0,13.47,0H20c4.34,0,8.68,0,13,0a3.36,3.36,0,1,0-.19-6.72H6.31A4.47,4.47,0,0,0,5,35.91,3.34,3.34,0,0,0,2.74,39.7Z"/>
      <path
        d="M37.65,60a1.35,1.35,0,0,0-1.12-1.67l-.44-.12c-4.61-1.19-9.22-2.39-13.84-3.55-.55-.14-.7-.37-.7-.92,0-3,0-6,0-9.08v-.8h-4c0,.26,0,.48,0,.7,0,3,0,6.06,0,9.09,0,.66-.21.9-.83,1.05-3,.75-6.06,1.54-9.09,2.31-1.7.44-3.41.88-5.11,1.34A1.34,1.34,0,0,0,1.44,60a1.36,1.36,0,0,0,1.71,1c.15,0,.29-.07.44-.1L14.78,58l3.42-.86c0,1.82,0,3.53,0,5.24,0,1.1.52,1.73,1.38,1.71s1.32-.63,1.33-1.68V57.14c.24,0,.39.06.53.1l10,2.58c1.46.38,2.92.77,4.39,1.12A1.35,1.35,0,0,0,37.65,60Z"/>
      <path
        d="M1.67,35.05a1.67,1.67,0,0,0,1.68-1.89q0-3.09,0-6.2V20.84c0-1.21-.68-2-1.71-2A1.73,1.73,0,0,0,0,20.82V33.14A1.68,1.68,0,0,0,1.67,35.05Z"/>
      <path
        d="M39.1,20.75a1.69,1.69,0,1,0-3.35,0c0,2.07,0,4.14,0,6.2s0,4.19,0,6.28a1.68,1.68,0,1,0,3.35,0Q39.11,27,39.1,20.75Z"/>
      <path d="M19.6,65.07a2.39,2.39,0,0,0-2.41,2.32,2.36,2.36,0,0,0,4.72.09A2.38,2.38,0,0,0,19.6,65.07Z"/>
      <path
        d="M2.77,62A2.38,2.38,0,0,0,.4,64.38a2.32,2.32,0,0,0,2.34,2.36,2.35,2.35,0,0,0,2.38-2.33A2.41,2.41,0,0,0,2.77,62Z"/>
      <path
        d="M36.42,62A2.41,2.41,0,0,0,34,64.32a2.36,2.36,0,0,0,2.29,2.42,2.3,2.3,0,0,0,2.42-2.27A2.35,2.35,0,0,0,36.42,62Z"/>
    </svg>
  )
}

export default Optimal

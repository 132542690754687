import React from "react"

function Author() {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 130.87 70" width="130"
         height="70">
      <path
        d="M130.75,19.14c-1-6-7.85-8.85-12.88-6.79a18.25,18.25,0,0,0-8.11,6.71,35.25,35.25,0,0,0-5.45,13.42A7.34,7.34,0,0,1,103,35.85a6.42,6.42,0,0,1-5.23,2.32H33.13c-.14,0-.27,0-.4,0a6,6,0,0,1-6-5.11A41.49,41.49,0,0,0,24,24.26,24.5,24.5,0,0,0,16.2,14,10.42,10.42,0,0,0,5.68,12.46C.12,14.68-1.75,21.15,1.84,25.63a9.49,9.49,0,0,0,7.83,3.61,2.85,2.85,0,0,1,2,.72A8,8,0,0,1,14,33.53a133.54,133.54,0,0,1,4.41,18.19,4.2,4.2,0,0,0,4.44,3.74h2.53c-.26,1.56-.5,3-.75,4.54q-.75,4.53-1.52,9.07c-.12.74,0,.92.8.92H26c1.19,0,1.3-.09,1.64-1.21,1.28-4.23,2.58-8.46,3.84-12.7a.74.74,0,0,1,.87-.63q33,0,66.09,0c.53,0,.77.14.92.67q1.93,6.45,3.91,12.88c.26.84.46,1,1.33,1H107c.66,0,.82-.21.71-.87q-.89-5.33-1.79-10.65c-.16-1-.31-2-.48-3,1.08,0,2.06,0,3,0a4.08,4.08,0,0,0,3.84-3.29c.4-2,.76-3.93,1.18-5.89a99.55,99.55,0,0,1,3.44-13,7.53,7.53,0,0,1,2.49-3.47,2.27,2.27,0,0,1,1.59-.55,8.2,8.2,0,0,0,1.92-.12,9.16,9.16,0,0,0,7.57-6.33c.14-.42.23-.85.34-1.27v-2C130.83,19.39,130.78,19.27,130.75,19.14Z"/>
      <path
        d="M24,20.51a39,39,0,0,1,4.43,12.57A4.34,4.34,0,0,0,30,35.66a4.39,4.39,0,0,0,2.9,1H98.32a4.28,4.28,0,0,0,4-3.25c.2-.79.34-1.61.52-2.41a35.49,35.49,0,0,1,6.22-13.81l.6-.78L108.39,16c-3.36-1-6.67-2.09-9.45-4.31C97.31,10.42,95.78,9,94.21,7.6a25.7,25.7,0,0,0-9.53-5.32,50.91,50.91,0,0,0-12-2,92,92,0,0,0-21.42.84,31.16,31.16,0,0,0-10.47,3.6,33.61,33.61,0,0,0-6.21,4.92,23.36,23.36,0,0,1-8.92,5.45c-1.45.47-2.91.91-4.41,1.38.14.19.27.35.39.53C22.44,18.15,23.31,19.29,24,20.51Zm57.61-6.09a13.85,13.85,0,0,0,.86-2.66c.44,1.87.79,3.66,2.87,4.62-2.08,1-2.44,2.72-2.88,4.47-.48-1.74-.81-3.55-2.61-4.33A13.16,13.16,0,0,0,81.63,14.42Zm-7.12,8.15C75,24.31,75.39,26,77.35,27c-1.94.95-2.39,2.65-2.84,4.4-.46-1.74-.85-3.47-2.86-4.39C73.64,26,74.07,24.32,74.51,22.57ZM64.6,7.06a13.84,13.84,0,0,0,.84-2.57,15.41,15.41,0,0,0,.86,2.59,13.07,13.07,0,0,0,1.74,2c-1.69.83-2.15,2.52-2.59,4.28-.47-1.73-.86-3.45-2.61-4.26A13.77,13.77,0,0,0,64.6,7.06ZM56.36,22.61c.47,1.73.88,3.43,2.85,4.35-1.92,1-2.42,2.63-2.84,4.41-.47-1.73-.86-3.45-2.86-4.38C55.47,26,55.93,24.36,56.36,22.61ZM48.42,11.9c.46,1.75.81,3.54,2.87,4.48-2,.95-2.41,2.73-2.86,4.49-.48-1.74-.79-3.55-2.71-4.49C47.61,15.41,48,13.65,48.42,11.9Z"/>
    </svg>
  )
}

export default Author

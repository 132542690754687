import React from "react"
import "./Process.scss"

function Process() {

  return (
    <section id="services" className="Process">
      <div className="container">
        <h2 className="section-title">Наши Услуги</h2>
        <ul className="Process-list">
          <li className="Process-row">
            <div className="Process-card">
              <div className="Process-number">
                01
              </div>
              <div className="Process-card-title">
                Проектирование как дизайн
              </div>
              <div className="Process-card-text">
                <p>
                  Команда профессионалов Hard Berry Design подходит открыто к каждому новому проекту.
                  Наши специалисты имеют немалый опыт работы и уникальное чутьё, что всегда помогает
                  принять «то самое» единственное верное дизайнерское решение для Вашего интерьера!
                  Обращайтесь к нам, мы создадим для Вас уникальный дизайн квартиры с неповторимым
                  шармом. Новый интерьер подарит Вам ощущение уюта, комфорта и свежие,
                  положительные эмоции.
                </p>
              </div>
            </div>
            <div className="Process-card">
              <div className="Process-number">
                02
              </div>
              <div className="Process-card-title">
                Авторское сопровождение
              </div>
              <div className="Process-card-text">
                <p>
                  Это гарантия того, что Ваша квартира, дом или любое другое помещение после ремонта
                  будет выглядеть точно так же, как и на 3-Д картинках визуализации. Контроль и
                  наблюдение за ремонтными работами крайне важен. Он обезопасит Вас от дальнейших
                  растрат денег и времени.
                  Авторский надзор является абсолютной необходимостью. Ошибки способны
                  полностью изменить Ваш интерьер, и задуманная картинка станет другой, поскольку
                  переделывать все в уже готовой квартире слишком затратно. Чтобы глупая неприятность не произошла с Вами, обязательно стоит воспользоваться услугой авторского надзора!
                </p>
              </div>
            </div>
          </li>
          <li className="Process-row">
            <div className="Process-card">
              <div className="Process-number">
                03
              </div>
              <div className="Process-card-title">
                Реализация. Ремонт под ключ
              </div>
              <div className="Process-card-text">
                <p>
                  Реализация проекта под ключ – правильное решение! Мы все ценим время и не тратим его напрасно. Когда
                  дело касается реализации дизайн-проекта, вместе со временем вырастает еще и ряд вопросов, которые
                  существенно усложняют процесс. Для реализации Вашего проекта, воплощения его в реальную жизнь нужно
                  потратить много усилий на поиск подходящих людей, которые способны понять и выполнить ваш проект, на
                  координацию подрядчиков с дизайнером, на организацию ремонтно- строительных работ, на бесконечные
                  телефонные звонки и встречи. При этом не всегда даже такие усилия приносят желаемый результат!
                </p>
                <p>
                  Вы должны быть готовы к тому, что взаимодействие между всеми участниками строительного процесса будет
                  весьма своеобразно- каждый имеет свою точку зрения и готов видеть только свою узко- поставленную
                  задачу,
                  не учитывая при этом технические условия и требования других участников процесса. Управление
                  строительством дает возможность выстраивать и согласовывать действия, их последовательность для всех
                  подрядчиков еще до начала ремонтных работ. Мы избежим дорогостоящих ошибок в дальнейшем и всегда несем
                  ответственность за результат.
                </p>
                <p>
                  В рамках услуги реализация "под ключ", вы получаете полное сопровождение на всех этапах от запуска и
                  до сдачи проекта. Так Вы обретаете возможность сократить ваши расходы до 25%, поскольку мы выстраиваем
                  выгодные условия сотрудничества с надежными поставщиками стройматериалов и подрядчиками на лояльных
                  тарифах.
                </p>
              </div>
            </div>
            <div className="Process-card">
              <div className="Process-number">
                04
              </div>
              <div className="Process-card-title">
                Консультация. Поможем купить
              </div>
              <div className="Process-card-text">
                <p>
                  Покупка нового жилья- ответственный выбор! Это решение сегодня формирует Вашу жизнь в будущем и к
                  такой покупке всегда относятся крайне ответственно. Мы помогаем сделать этот выбор осознанно. Как это
                  происходит? Вы оставляете заявку, и в течении 30 минут наш специалист связывается с Вами. Есть 2 пути
                  сотрудничества: дистанционный и с выездом на объект. Ответив на ряд вопросов и заполнив анкету, в
                  течении двух- четырех дней Вы получаете аналитику всех возможностей Вашего будущего дома. Консультацию
                  специалиста по нужной Вам планировке, переносу коммуникаций, целесообразности этих решений. Приобретая
                  такое жилье Вы уже знаете, что в итоге получаете. И «кот в мешке» это уже не о Вас!
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>
  )
}

export default Process

import React from "react"
import SEO from "../components/seo"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Banner } from "../components/main-page/Banner"
import { Layout } from "../components/parts/Layout"
import { AboutUs } from "../components/main-page/AboutUs"
import { Steps } from "../components/main-page/Steps"
import { Portfolio } from "../components/main-page/Portfolio"
import { Process } from "../components/main-page/Process"
import { PricePlans } from "../components/main-page/PricePlans"
import { ContactUs } from "../components/main-page/ContactUs"

const IndexPage = () => (
  <Layout>
    <SEO/>
    <Banner/>
    <AboutUs/>
    <Steps/>
    <Portfolio/>
    <Process/>
    <PricePlans/>
    <ContactUs/>
  </Layout>
)

export default IndexPage

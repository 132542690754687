import React from "react"

function Premium() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 73.48 53.93" width="74" height="54">
      <path
        d="M73.45,23.09a6.62,6.62,0,0,0-12.64-1.95c-.88,2-1.78,4.06-2.68,6.1-.19.43-.39.85-.6,1.3a71.45,71.45,0,0,0-41.52,0,.47.47,0,0,1-.1-.09c-1-2.25-2.08-4.47-3-6.77a6.76,6.76,0,0,0-7.16-4.43A6.66,6.66,0,0,0,0,23a6.59,6.59,0,0,0,4.46,7,.49.49,0,0,1,.39.48c.34,2.93.78,5.86,1,8.8a5.62,5.62,0,0,0,3.78,5.06,11,11,0,0,0,4.38.89c2.64,0,5.28,0,7.91,0H38.52c5.55,0,11.1,0,16.65,0,1.95,0,3.92,0,5.87-.15a8.42,8.42,0,0,0,4.46-1.69,4.68,4.68,0,0,0,1.91-2.88c.16-.86.22-1.73.32-2.6.29-2.48.59-5,.88-7.44a.5.5,0,0,1,.38-.48A6.53,6.53,0,0,0,73.45,23.09Z"/>
      <path
        d="M6.79,13.81a10,10,0,0,1,9.06,6c.54,1.13,1,2.3,1.49,3.46.1.23.21.46.32.69.45-.11.85-.25,1.25-.31,2.61-.39,5.21-.82,7.82-1.12A72.25,72.25,0,0,1,37.83,22c3.67.13,7.35.37,11,.73,2.26.22,4.5.73,6.74,1.13.29.05.34-.09.42-.28.51-1.19,1-2.36,1.52-3.55a9.68,9.68,0,0,1,3.86-4.61,9.92,9.92,0,0,1,6.33-1.54,12.22,12.22,0,0,0-.83-4.95A13.76,13.76,0,0,0,53.81,0H22.27C20.85,0,19.42,0,18,.11A13.7,13.7,0,0,0,6.06,10.87c-.2,1-.27,2-.39,2.93C6.12,13.8,6.46,13.79,6.79,13.81Z"/>
      <path
        d="M16.12,48.17c-.85,0-1.71,0-2.57,0a9.4,9.4,0,0,1-5.43-1.41c0,1.65-.08,3.24,0,4.82a2.71,2.71,0,0,0,2.69,2.32c1,0,2,0,3,0a2.71,2.71,0,0,0,2.7-2.26,28.44,28.44,0,0,0,0-3.46Z"/>
      <path
        d="M65.33,46.76a5.67,5.67,0,0,1-2.93,1.18c-1,.13-2.08.18-3.11.22-.81,0-1.62,0-2.45,0,0,1.09-.05,2.11,0,3.13a2.8,2.8,0,0,0,2.7,2.6c1,0,2.08,0,3.12,0a2.82,2.82,0,0,0,2.74-2.66c.06-1.46,0-2.94,0-4.41Z"/>
    </svg>
  )
}

export default Premium
